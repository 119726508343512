var name = 'bookingChoose';
page.names.push(name);

page[name] = class PageClassName extends page.GenPage{



    init(ajaxBool = false){

        super.init();

        page.helpers.tooltips();

    }



};

page[name] = new page[name];
page[name] = page[name].create();